const getCountries = async () => {
    const getFilter = (list, condition) => (
        list.map(c => ({ name: c.name.common, flag: c.flags.svg }))
        .filter(pais => condition(pais.name)).sort((a, b) => a.name > b.name ? 1 : -1)
    );
    const countriesPromise = await fetch('https://restcountries.com/v3.1/all?fields=name,flags');
    const countries = await countriesPromise.json();
    const listOfPaises = getFilter(countries, (value) => !['Argentina', 'Italy'].includes(value));
    const listOfSpecialPaises = getFilter(countries, (value) => ['Argentina', 'Italy'].includes(value));
    listOfSpecialPaises[1].name = 'Italia';
    return { 
        listOfPaises, 
        listOfSpecialPaises 
    };
}

export default getCountries;