import Header from '../components/Header';
import Creators from '../components/Creators';

const Faq = () => (
    <div className='container faq-section'>
        <Header showBack />
        <h2>FAQ</h2>
        <p>Preguntas y respuestas sobre las principales dudas que pueden surgir.</p>
        <span className='question'>&iquest;Esta herramienta es infalible?</span>
        <p>Y la verdad que no. Te va a tirar la posta de acuerdo al &aacute;rbol que VOS armaste. Pero ten&eacute; en cuenta que va a haber factores o alternativas que puedan hacer cambiar tu situaci&oacute;n. Algunos pueden ser:</p>
        <ul>
            <li>Tu familiar italiano no debe haber renunciado a la ciudadan&iacute;a italiana. Valga la redundancia.</li>
            <li>A veces creemos que el mejor camino es ir por ejemplo por nuestra bisabuela italiana (mujer), pero no tenemos en cuenta que aunque su marido (nuestro bisabuelo) no sea italiano, sus padres pueden serlo. Ahi podr&iacute;as ir una generaci&oacute;n m&aacute;s arriba y ahorrarte el juicio.</li>
        </ul>
        <span className='question'>&iquest;Cu&aacute;l es el limite de generaciones que puedo subir? (abuelo, bisabuelo, tatarabuelo, etc)</span>
        <p>Si el ancestro italiano falleci&oacute; antes del 17/03/1861 ahi no pod&eacute;s solicitar el reconocimiento de la ciudadan&iacute;a italiana. Pero m&aacute;s all&aacute; de esto no habr&iacute;a limitantes de generaciones.</p>
        <Creators />
    </div>
);

export default Faq;