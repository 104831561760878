import { Link } from "react-router-dom";

import siamoDentro from '../../images/siamodentro.svg';
import Back from '../../images/back.svg';

const Header = ({ showBack = false }) => (
    <div className='header'>
        {showBack && <Link to='/' className='back'><img src={Back} alt="Back" /></Link>}
        <h1 className={ showBack ? 'extra-margin' : null }><Link to='/'><img src={siamoDentro} alt='Siamo Dentro' /></Link></h1>
    </div>
);

export default Header;