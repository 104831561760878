import { Link } from "react-router-dom";

import Button from '../../UI/Button';
import Creators from '../../components/Creators';
import warning from '../../images/warning.svg';

const Home = ({ openModal }) => (
  <>
    <h2 className="sub-title">Ciudadan&iacute;a Italiana</h2>
    <h2>&iquest;Administrativa o Judicial?</h2>
    <p>Creamos esta herramienta para que puedas saber facilmente si tu caso corresponde a la <span className='bold'>v&iacute;a administrativa</span> o a la <span className='bold'>v&iacute;a judicial.</span></p>
    <p>Arm&aacute; tu &aacute;rbol familiar y te decimos si te toca o no juicio.</p>
    <Button click={openModal}>Armar &aacute;rbol ahora</Button>
    <div className='bottom-container'>
      <div className='box-questions'>
        <h3>&iquest;Ten&eacute;s preguntas, dudas?</h3>
        <p>Ac&aacute; respondemos las preguntas que m&aacute;s salen.</p>
        <Link className='btn-secondary' to='/faq'>Ver preguntas frecuentes</Link>
      </div>
      <div className='faq-warning'>
        <img src={warning} alt="Warning" />
        <div className='faq-text'>Te aclaramos igualmente que el resultado que te pueda dar esta herramienta es estrictamente relacionado con el &aacute;rbol que vos mismo armaste. Por las dudas mir&aacute; la secci&oacute;n <Link to="faq"><span className='underline'>FAQ.</span></Link></div>
      </div>
      <Creators />
    </div>
  </>
);

export default Home;
