import { Fragment, useState } from 'react';

import Button from '../../UI/Button';
import EmptyBlock from '../../UI/EmptyBlock';
import Block from '../../UI/Block';
import ArrowDown from '../../images/arrow_down.svg';

const Arbol = ({ openModal, users, reset, editClick, isValidated, setIsValidated, volverAValidar, setVolverAValidar }) => {
  const [isJudicial, setIsJudicial] = useState(false);
  const [resultText, setResultText] = useState('');
  const [firstChildId, setFirstChildId] = useState(null);

  const getPadreGenero = (genero) => genero === 'Hombre' ? 'padre' : 'madre';
  const getAbueloGenero = (genero) => genero === 'Hombre' ? 'abuelo' : 'abuela';
  const getBisabueloGenero = (genero) => genero === 'Hombre' ? 'bisabuelo' : 'bisabuela';
  const getTatarabueloGenero = (genero) => genero === 'Hombre' ? 'tatarabuelo' : 'tatarabuela';
  const getTrastatarabueloGenero = (genero) => genero === 'Hombre' ? 'trastatarabuelo' : 'trastatarabuela';

  const getText = () => {
    let buttonText = '';
    let nextText = '';
    let statusText = '';
    const vos = 'Por ahora estás <span class="bold">vos</span>';
    const padre = () => `tu ${getPadreGenero(users[1].genero)} <span class="bold">${users[1].nombre}</span>`;
    const abuelo = () => `tu ${getAbueloGenero(users[2].genero)} <span class="bold">${users[2].nombre}</span>`;
    const bisabuelo = () => `tu ${getBisabueloGenero(users[3].genero)} <span class="bold">${users[3].nombre}</span>`;
    const tatarabuelo = () => `tu ${getTatarabueloGenero(users[4].genero)} <span class="bold">${users[4].nombre}</span>`;
    const trastatarabuelo = () => `tu ${getTrastatarabueloGenero(users[5].genero)} <span class="bold">${users[5].nombre}</span>`;

    switch(users.length) {
      case 1:
        buttonText = '+ Agregar madre o padre';
        nextText = 'Madre o Padre';
        statusText = 'Estás solo en el árbol.';
        break;
      case 2:
        buttonText = '+ Agregar abuela o abuelo';
        nextText = 'Abuela o abuelo';
        statusText = `${vos} y ${padre()}.`;
        break;
      case 3:
        buttonText = '+ Agregar bisabuela o bisabuelo';
        nextText = 'Bisabuela o bisabuelo';
        statusText = `${vos}, ${padre()} y ${abuelo()}.`;
        break;
      case 4:
        buttonText = '+ Agregar tatarabuela o tatarabuelo';
        nextText = 'Tatarabuela o tatarabuelo';
        statusText = `${vos}, ${padre()}, ${abuelo()} y ${bisabuelo()}.`;
        break;
      case 5:
        buttonText = '+ Agregar trastatarabuela o trastatarabuelo';
        nextText = 'Trastatarabuela o trastatarabuelo';
        statusText = `${vos}, ${padre()}, ${abuelo()}, ${bisabuelo()} y ${tatarabuelo()}.`;
        break;
      case 6:
        buttonText = '+ Agregar trastatarabuela o trastatarabuelo';
        nextText = 'Trastatarabuela o trastatarabuelo';
        statusText = `${vos}, ${padre()}, ${abuelo()}, ${bisabuelo()}, ${tatarabuelo()} y ${trastatarabuelo()}.`;
        break;
      default:
        buttonText = '+ Agregar trastatarabuela o trastatarabuelo';
        nextText = 'Trastatarabuela o trastatarabuelo';
        statusText = `${vos}, ${padre()}, ${abuelo()}, ${bisabuelo()}, ${tatarabuelo()} y ${trastatarabuelo()}.`;
    }
    return { buttonText, nextText, statusText };
  }

  const checkIfItalian = () => users[users.length - 1].pais === 'Italia';

  const getUserBlock = (user) => {
    let userType = '';
    switch(user.idx) {
      case 0:
        userType = 'yo';
        break;
      case 1:
        userType = getPadreGenero(user.genero);
        break;
      case 2:
        userType = getAbueloGenero(user.genero);
        break;
      case 3:
        userType = getBisabueloGenero(user.genero);
        break;
      case 4:
        userType = getTatarabueloGenero(user.genero);
        break;
      case 5:
        userType = getTrastatarabueloGenero(user.genero);
        break;
      default:
        userType = getBisabueloGenero(user.genero);
    }
    return (
      <Fragment key={user.nombre}>
        <div className='arrow_down'>
          <img src={ArrowDown} alt='Arrow down' />
        </div>
        <Block 
          user={user} 
          userType={userType} 
          editClick={() => editClick(user.idx)}
          backRed={isValidated && isJudicial && user.idx === firstChildId} />
      </Fragment>
    );
  }

  const validar = () => {
    let founded = false;
    let firstWomen = '';
    let firstChild;
    users.toReversed().forEach(user => {
      if (user.genero === 'Mujer' && !founded) {
        firstWomen = user.nombre;
        firstChild = users[user.idx - 1];
        setFirstChildId(firstChild.idx);
        founded = true;
      }    
    });
    const esJudicial = founded && firstChild?.ano < 1948;
    if (founded) {
      setResultText(`La primera mujer en tu árbol es <span class="bold">${firstWomen}</span>, ${firstChild.genero === 'Hombre' ? 'el hijo' : 'la hija'} de ella es <span class="bold">${firstChild.nombre}</span> quien nació ${esJudicial ? 'antes' : 'después'} de <span class="bold">1948.</span>`);
    } else {
      setResultText('No hay mujeres en tu árbol.');
    }
    setIsValidated(true);
    setIsJudicial(esJudicial);
    setVolverAValidar(false);
  }

  return (
      <>
        {isValidated ? (
          <h2 className='resultado-via'>Tu caso corresponde a: <span className={`via ${isJudicial ? 'via-judicial' : 'via-administrativa'}`}>{`${isJudicial ? 'Vía Judicial.' : 'Vía Administrativa.'}`}</span></h2>
          )
          : 
          <h2>Tu &aacute;rbol por ahora est&aacute; as&iacute;:</h2>}
        {isValidated ? <p dangerouslySetInnerHTML={{__html: resultText}} /> :
        checkIfItalian() ?
          <p>Bien! Ya llegaste a un Italiano en la cadena. Ya podés comprobar si tu caso corresponde a Vía Administrativa o Vía Judicial.</p>
          : 
          <>
            <p style={{ marginBottom: 0 }} dangerouslySetInnerHTML={{__html: getText().statusText}} />
            <p>Ten&eacute;s que agregar m&aacute;s miembros a tu familia hasta llegar a alguien Italiano.</p>
          </>
        }
        {!checkIfItalian() && (
          <>
            <Button extraClass='add-familiar' click={users.length > 5 ? null : openModal} disabled={users.length > 5}>{getText().buttonText}</Button>
            <EmptyBlock>{getText().nextText}</EmptyBlock>
          </>
        )}
        <div className='user-list-container'>
          {users.toReversed().map((user) => getUserBlock(user))}
        </div>
        {!isValidated ? 
          <footer className='footer-comprobar'>
            <Button disabled={!checkIfItalian()} click={validar}>Comprobar</Button>
          </footer>
          :
          <>
            <div className='footer-new-arbol'>
              {isJudicial && <p>Pero no te preocupes, igualmente podés realizar el <span className='bold'>Juicio por Vía Materna.</span></p>}
              {volverAValidar ?  
                <footer className='footer-comprobar volver_a_comprobar'>
                  <Button click={reset} primary={false}>Armar nuevo árbol</Button>
                  <Button disabled={!checkIfItalian()} click={validar}>Volver a Comprobar</Button>
                </footer>
              :
                <Button click={reset}>Armar un nuevo árbol</Button>
              }
            </div>
          </>
        }
    </>
  );
};

export default Arbol;