import { Route, Routes } from 'react-router-dom';

import ArbolContainer from './containers/ArbolContainer';
import Faq from './containers/Faq';

const App = () => {
  return (
    <Routes>
      <Route path='/' element={<ArbolContainer />} />
      <Route path='/faq' element={<Faq />} />
    </Routes>
  );
}

export default App;
