import { useEffect, useState } from 'react';

import getCountries from '../../utils/getCountries';
import Hombre from '../../images/hombre.svg';
import Mujer from '../../images/mujer.svg';
import Edit from '../../images/edit.svg';
import './block.scss';

const Block = ({ user, userType, backRed = false, editClick }) => {
  const [flagSrc, setFlagSrc] = useState('');
  
  useEffect(() => {
    getFlag();
  }, [user.pais]);

  const getFlag = async () => {
    const { listOfPaises, listOfSpecialPaises } = await getCountries();
    const paises = [...listOfPaises, ...listOfSpecialPaises];
    setFlagSrc(paises.filter(pais => pais.name === user.pais)[0].flag);
  }

  return (
    <div className={`block_user ${backRed ? 'block_user_back_red' : ''}`}>
      <span className='ano'>{user.ano}</span>
      <div className='name-type'>
        <span className='name'>{user.nombre}</span>
        <span className='person_type'>{`(${userType})`}</span>
      </div>
      <div className='block_right'>
        <img src={flagSrc} alt='pais' className='flag' />
        <img src={user.genero === 'Hombre' ? Hombre : Mujer} alt={user.genero} className='genero' />
        <span className='genero_txt'>{user.genero}</span>
        <div className='edit_container' onClick={editClick}>
            <img src={Edit} alt='Edit' />
        </div>
      </div>
    </div>
  );
};

export default Block;